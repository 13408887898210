import { template as template_23ad508a4b2d4828ae7ebc1b8469f6b4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_23ad508a4b2d4828ae7ebc1b8469f6b4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
