import { template as template_f7f3ece3462c4298a087563b2d8cd173 } from "@ember/template-compiler";
import avatar from "discourse/helpers/avatar";
const PostersColumn = template_f7f3ece3462c4298a087563b2d8cd173(`
  <td class="posters topic-list-data">
    {{#each @posters as |poster|}}
      {{#if poster.moreCount}}
        <a class="posters-more-count">{{poster.moreCount}}</a>
      {{else}}
        <a
          href={{poster.user.path}}
          data-user-card={{poster.user.username}}
          class={{poster.extraClasses}}
        >{{avatar
            poster
            avatarTemplatePath="user.avatar_template"
            usernamePath="user.username"
            namePath="user.name"
            imageSize="small"
          }}</a>
      {{/if}}
    {{/each}}
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostersColumn;
